
import { defineComponent } from 'vue';
import { mapState } from 'vuex';
import { BOTTOM, CENTER, LEFT, RIGHT } from '@shell/utils/position';

type Zone = null | typeof CENTER | typeof RIGHT | typeof BOTTOM | typeof LEFT;

export interface Drag {
  active: boolean;
  zone: Zone;
}

interface Data {
  drag: Drag;
}

export default defineComponent({
  data(): Data {
    return {
      drag: {
        active: false,
        zone:   CENTER,
      },
    };
  },

  computed: {

    ...mapState('wm', ['userPin']),

    pin: {
      get(): Zone {
        return this.userPin;
      },

      set(pin: Zone) {
        if (pin === CENTER) {
          return;
        }
        window.localStorage.setItem('wm-pin', pin as string);
        this.$store.commit('wm/setUserPin', pin);
      },
    },

  },

  methods: {

    onDragStart() {
      this.drag.active = true;
    },

    onDragOver(event: DragEvent, zone: Zone) {
      this.drag.zone = zone;
      if (zone !== CENTER) {
        event.preventDefault();
      }
    },

    onDragEnd() {
      this.pin = this.drag.zone;
      this.drag = {
        active: false,
        zone:   CENTER,
      };
    },

  }
});
